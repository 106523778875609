import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { ApiService } from 'src/app/services/api.service'
import { freelanceService } from 'src/app/services/freelance-agent.service'
import { FreelanceForm } from 'src/app/services/interface/freelance-agent.model'
import { QueryTable } from 'src/app/services/interface/general.model'

@Component({
  selector: 'app-add-freelance-agent',
  templateUrl: './add-freelance-agent.component.html',
  styleUrls: ['./add-freelance-agent.component.scss'],
})
export class AddFreelanceAgentComponent implements OnInit {
  form: FormGroup
  isLoading: boolean = false
  totalData: number = 0
  pageSize: number = 5
  pageIndex: number = 1
  constructor(
    private fb: FormBuilder,
    private freelanceService: freelanceService,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
  ) {
    this.form = this.fb.group({
      name: [null, Validators.compose([Validators.required])],
      phonePrefix: ['+62'],
      phone: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.required])],
    })
  }

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  ngOnInit(): void {
    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }
    this.form.statusChanges.subscribe(state => {
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
  }

  submitForm() {
    this.isLoading = true
    // console.log(this.form.getRawValue())

    for (const i in this.form.controls) {
      if (this.form.controls.hasOwnProperty(i)) {
        this.form.controls[i].markAsDirty()
        this.form.controls[i].updateValueAndValidity()
      }
    }
    if (this.form.valid) {
      var formSubs: FreelanceForm = this.form.value
      // console.log(this.form.value)
      // formSubs.username = this.user.username
      this.freelanceService.createFreelance(formSubs).subscribe(
        r => {
          this.notification.success('Success', r.message)
          this.isLoading = false
          this.modal.destroy()
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return true
        },
        err => {
          this.notification.error('Error', err.error.message)
          this.isLoading = false
          this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
          return false
        },
      )
    } else {
      this.msg.error('Please Fill Blank Form')
      this.isLoading = false
      this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
      return false
    }
  }
}
