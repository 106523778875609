import { HttpClient } from '@angular/common/http'
import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef } from 'ng-zorro-antd/modal'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { ApiService } from 'src/app/services/api.service'
import { freelanceService } from 'src/app/services/freelance-agent.service'
import { FreelanceDetails, FreelanceForm } from 'src/app/services/interface/freelance-agent.model'

@Component({
  selector: 'app-edit-freelance-agent',
  templateUrl: './edit-freelance-agent.component.html',
  styleUrls: ['./edit-freelance-agent.component.scss'],
})
export class EditFreelanceAgentComponent implements OnInit {
  form: FormGroup
  @Input() id: string
  constructor(
    private fb: FormBuilder,
    private freelanceService: freelanceService,
    private apiService: ApiService,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    private modal: NzModalRef,
    private http: HttpClient,
  ) {
    this.form = this.fb.group({
      name: [null, Validators.compose([Validators.required])],
      phonePrefix: ['+62'],
      phone: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.required])],
    })
  }

  data = []
  detailsData: FreelanceDetails
  ngAfterViewInit(): void {}
  isLoading: boolean = true

  ngOnInit(): void {
    this.fetchFreelanceDetails()
    this.form.statusChanges.subscribe(state => {
      // console.log(state)
      this.modal.updateConfig({ nzOkDisabled: state === 'INVALID' })
    })
  }

  fetchFreelanceDetails() {
    this.isLoading = true
    this.freelanceService.detailFreelance(this.id).subscribe(
      res => {
        this.form.patchValue(res)
        // console.log(this.form.value)
        this.isLoading = false
        // for (const i in this.form.controls) {
        //   if (this.form.controls.hasOwnProperty(i)) {
        //     this.form.controls[i].markAsDirty()
        //     this.form.controls[i].updateValueAndValidity()
        //   }
        // }
      },
      // err => {
      //   this.notification.error('Error', err.error.message)
      // },
    )
  }

  // submitForm(): boolean {
  //   this.isLoading = true
  //   this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })

  //   for (const i in this.form.controls) {
  //     if (this.form.controls.hasOwnProperty(i)) {
  //       this.form.controls[i].markAsDirty()
  //       this.form.controls[i].updateValueAndValidity()
  //     }
  //   }
  //   if (this.form.valid) {
  //     var formSubs: FreelanceForm = this.form.value
  //     formSubs.id = this.id
  //     // formSubs.content = toHTML(this.form.value.content)
  //     this.freelanceService.updateFreelance(formSubs).subscribe(
  //       r => {
  //         this.notification.success('Success', r.message)
  //         this.isLoading = false
  //         this.modal.destroy()
  //         this.fetchDropPointDetails()
  //         return true
  //       },
  //       err => {
  //         this.notification.error('Error', err.error.message)
  //         this.isLoading = false
  //         this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
  //         this.fetchDropPointDetails()
  //         return false
  //       },
  //     )
  //   } else {
  //     this.msg.error('Please Fill Blank Form')
  //     this.isLoading = false
  //     this.modal.updateConfig({ nzOkLoading: this.isLoading, nzCancelDisabled: this.isLoading })
  //     this.fetchDropPointDetails()
  //     return false
  //   }
  // }
}
