import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { MaterialProModule } from './material-pro-module'

import { NgProgressModule } from '@ngx-progressbar/core'
import { NgProgressRouterModule } from '@ngx-progressbar/router'
import { NgProgressHttpModule } from '@ngx-progressbar/http'
// import { AngularFireModule } from '@angular/fire/compat'
// import { AngularFireAuthModule } from '@angular/fire/compat/auth'
// import { AngularFirestoreModule, SETTINGS } from '@angular/fire/compat/firestore'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { reducers, metaReducers } from './store/reducers'
import { UserEffects } from './store/user/effects'
import { DatePipe } from '@angular/common'

// locale resistration
import { registerLocaleData } from '@angular/common'
import { default as localeEn } from '@angular/common/locales/en'
import { NZ_I18N, en_US as localeZorro } from 'ng-zorro-antd/i18n'
import { ApiService } from './services/api.service'
import { TicketingService } from './services/ticketing.service'
import { AssetManagementService } from './services/asset-management.service'
import { Router } from '@angular/router'
import { JwtInterceptor } from './services/jwt.interceptor'
import { HrService } from './services/hr.service'
import { CalendarModule, DateAdapter } from 'angular-calendar'
import { adapterFactory } from 'angular-calendar/date-adapters/moment'
import * as moment from 'moment'
import { NgxEditorModule } from 'ngx-editor'
import { Globals } from './globals'
import { LoadingModalComponent } from './loading-modal/loading-modal.component'
import { DialogDecisionComponent } from './dialog-decision/dialog-decision.component'
import { BrandingApprovalService } from './services/branding-approval.service'
import { CapitalizeWord } from './shared/pipes/capitalize-word.pipe'
import { NumberDirective } from './shared/directive/onlyNumber.directive'

export function momentAdapterFactory() {
  return adapterFactory(moment)
}
const LOCALE_PROVIDERS = [
  { provide: LOCALE_ID, useValue: 'en' },
  { provide: NZ_I18N, useValue: localeZorro },
]
registerLocaleData(localeEn, 'en')

@NgModule({
  declarations: [
    AppComponent,
    LoadingModalComponent,
    DialogDecisionComponent,
    CapitalizeWord,
    NumberDirective,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialProModule,
    FormsModule,
    NgxEditorModule,
    AppRoutingModule,

    // translate
    TranslateModule.forRoot(),

    // ngrx
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([UserEffects]),
    StoreRouterConnectingModule.forRoot(),

    // nprogress
    NgProgressModule.withConfig({
      thick: true,
      spinner: false,
      color: '#0190fe',
    }),
    NgProgressRouterModule,
    NgProgressHttpModule,

    // init firebase
    // AngularFireModule.initializeApp(firebaseConfig),
    // AngularFireAuthModule,
    // AngularFirestoreModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
  ],
  providers: [
    // auth services
    // firebaseAuthService,
    ApiService,
    TicketingService,
    AssetManagementService,
    HrService,
    BrandingApprovalService,
    DatePipe,

    // fake http interceptors
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MockHttpCallInterceptor,
    //   multi: true,
    // },
    //   {
    //     provide: HTTP_INTERCEPTORS,
    //     useFactory: function (router: Router) {
    //         return new JwtInterceptor(router);
    //     },
    //     multi: true,
    //     deps: [Router]
    // },

    // locale providers
    ...LOCALE_PROVIDERS,

    // firestore settings
    // { provide: SETTINGS, useValue: {} },
    Globals,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
