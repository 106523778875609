import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal'

import { FormBuilder, FormGroup, Validators } from '@angular/forms'

import { AssetInventoryServiceService } from 'src/app/services/asset-inventory-service.service'
import { Inventories, addAsset } from 'src/app/models/asset-inventory.model'
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload'
import { NzMessageService } from 'ng-zorro-antd/message'
import { ApiService } from '../../services/api.service'
import { Observable, Observer } from 'rxjs'
import { Company, ConfigValue, vendorDetail } from 'src/app/models/asset-config.model'
import { AssetManagementService } from 'src/app/services/asset-management.service'
import { Employee } from 'src/app/models/asset-inventory.model'
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { CapitalizeWord } from './../../shared/pipes/capitalize-word.pipe'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzTableQueryParams } from 'ng-zorro-antd/table'

pdfMake.vfs = pdfFonts.pdfMake.vfs
@Component({
  selector: 'app-asset-management',
  templateUrl: './asset-management.component.html',
  styleUrls: ['./asset-management.component.scss'],
})
export class AssetManagementComponent implements OnInit {
  @ViewChild('toaster', { static: true }) toasterTemplate: TemplateRef<{}>
  confirmModal?: NzModalRef
  isLoading: boolean = true
  totalData: number = 0
  pageSize: number = 5
  pageIndex: number = 1

  getAsset: Inventories[] = []
  selectedCompany: string = 'Onindonesia'
  selectedAsset: Inventories

  filterForm: FormGroup
  isDownloading: boolean = false

  isAddAssetVisible: boolean = false
  isAddAssetConfirm: boolean = false
  isUpdateAssetConfirm: boolean = false

  loading = false
  avatarUrl?: string

  previewImage: string | undefined = ''
  previewVisible = false

  assetsForm: FormGroup

  importExcelFile: NzUploadFile[] = []

  searchAutoCompleteList: any[] = []
  companyList: Company[] = []
  employeeList: Employee[] = []
  departmentList: ConfigValue[] = []
  typeList: ConfigValue[] = []
  statusList: ConfigValue[] = []
  brandList: ConfigValue[] = []
  conditionList: ConfigValue[] = []
  locationList: ConfigValue[] = []
  vendorList: ConfigValue[] = []
  employeePictList: NzUploadFile[] = []
  assetPictList: NzUploadFile[] = []
  documentList: NzUploadFile[] = []
  //view variable
  assetViewForm: FormGroup
  viewEmployeePicture: NzUploadFile[] = []
  viewAssetPicture: NzUploadFile[] = []
  isViewLoading: boolean = false
  isViewVisible: boolean = false

  toasterIcon: string
  toasterColor: string
  toasterMessage: string

  constructor(
    private assetInventoryService: AssetInventoryServiceService,
    private assetManagementService: AssetManagementService,
    private modal: NzModalService,
    private fb: FormBuilder,
    private msg: NzMessageService,
    private apiService: ApiService,
    private notification: NzNotificationService,
  ) {
    this.filterForm = this.fb.group({
      company: ['', Validators.compose([Validators.required])],
      filter: ['', Validators.compose([Validators.required])],
      search: ['', Validators.compose([])],
    })

    this.assetsForm = this.fb.group({
      selectedEmployee: [null, Validators.compose([Validators.required])],
      employeeName: ['', Validators.compose([Validators.required])],
      department: ['', Validators.compose([Validators.required])],
      employeePicture: ['', Validators.compose([Validators.required])],
      company: ['', Validators.compose([Validators.required])],
      phoneNumber: ['', Validators.compose([Validators.required])],
      email: [''],
      assetName: ['', Validators.compose([Validators.required])],
      serialNumber: [''],
      status: ['', Validators.compose([Validators.required])],
      service: [null, Validators.compose([Validators.pattern('^[0-9]*$')])],
      serviceAt: [''],
      location: ['', Validators.compose([Validators.required])],
      assetPicture: ['', Validators.compose([Validators.required])],
      type: ['', Validators.compose([Validators.required])],
      brand: ['', Validators.compose([Validators.required])],
      condition: ['', Validators.compose([Validators.required])],
      description: [''],
      purchasedDate: ['', Validators.compose([Validators.required])],
      initialValue: [null, Validators.compose([Validators.required])],
      depreciationRate: [
        null,
        Validators.compose([Validators.required, Validators.pattern('^[0-9]+([.][0-9]+)?$')]),
      ],
      period: [null, Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')])],
      note: [''],
      document: [''],
      vendor: [''],
      vendorPICName: [''],
      vendorEmail: [''],
      vendorPhone: [null],
      vendorIndustries: [''],
    })

    this.assetViewForm = this.fb.group({
      selectedEmployee: [null, Validators.compose([Validators.required])],
      employeeName: ['', Validators.compose([Validators.required])],
      department: ['', Validators.compose([Validators.required])],
      company: ['', Validators.compose([Validators.required])],
      phoneNumber: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required])],
      assetName: ['', Validators.compose([Validators.required])],
      serialNumber: [''],
      status: ['', Validators.compose([Validators.required])],
      service: [null],
      serviceAt: [''],
      location: ['', Validators.compose([Validators.required])],
      type: ['', Validators.compose([Validators.required])],
      brand: ['', Validators.compose([Validators.required])],
      condition: ['', Validators.compose([Validators.required])],
      description: [''],
      purchasedDate: ['', Validators.compose([Validators.required])],
      initialValue: [null, Validators.compose([Validators.required])],
      depreciationRate: [null, Validators.compose([Validators.required])],
      period: [null, Validators.compose([Validators.required])],
      note: [''],
      document: [''],
      vendor: [''],
      vendorPICName: [''],
      vendorEmail: [''],
      vendorPhone: [null],
      vendorIndustries: [''],
    })
  }

  listOfColumns = [
    {
      name: 'Actions',
      key: 'actions',
      left: false,
      right: false,
      width: '125px',
    },
    {
      name: 'Asset Number',
      key: 'assets_number',
      left: false,
      right: false,
      width: '230px',
      compare: (a: Inventories, b: Inventories) => a.asset_number.localeCompare(b.asset_number),
      priority: false,
    },
    {
      name: 'Asset Name',
      key: 'assets_name',
      left: false,
      right: false,
      width: '150px',
      compare: (a: Inventories, b: Inventories) => a.asset_name.localeCompare(b.asset_name),
      priority: 21,
    },
    {
      name: 'Employee Name',
      key: 'employee_name',
      left: false,
      right: false,
      width: '200px',
      compare: (a: Inventories, b: Inventories) => a.employee_name.localeCompare(b.employee_name),
      priority: 20,
    },
    {
      name: 'Department',
      key: 'department',
      left: false,
      right: false,
      width: '200px',
      compare: (a: Inventories, b: Inventories) => a.department.localeCompare(b.department),
      priority: 19,
    },

    {
      name: 'Type',
      key: 'type',
      left: false,
      right: false,
      width: '200px',
      compare: (a: Inventories, b: Inventories) => a.type.localeCompare(b.type),
      priority: 18,
    },
    {
      name: 'Serial Number',
      key: 'serial_number',
      left: false,
      right: false,
      width: '150px',
      compare: (a: Inventories, b: Inventories) => a.serial_number.localeCompare(b.serial_number),
      priority: 17,
    },

    {
      name: 'Status',
      key: 'status',
      left: false,
      right: false,
      width: '200px',
      compare: (a: Inventories, b: Inventories) => a.status.localeCompare(b.status),
      priority: 16,
    },
    {
      name: 'Condition',
      key: 'condition',
      left: false,
      right: false,
      width: '130px',
      compare: (a: Inventories, b: Inventories) => a.condition.localeCompare(b.condition),
      priority: 15,
    },
    {
      name: 'Brand',
      key: 'brand',
      left: false,
      right: false,
      width: '200px',
      compare: (a: Inventories, b: Inventories) => a.brand.localeCompare(b.brand),
      priority: 14,
    },
    {
      name: 'Notes',
      key: 'note',
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Service',
      key: 'service',
      left: false,
      right: false,
      width: '200px',
      compare: (a: Inventories, b: Inventories) => a.service - b.service,
      priority: 12,
    },
    {
      name: 'Service At',
      key: 'service_at',
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Location',
      key: 'location',
      left: false,
      right: false,
      width: '200px',
      compare: (a: Inventories, b: Inventories) => a.location.localeCompare(b.location),
      priority: 10,
    },
    {
      name: 'Registered Date',
      key: 'registered_date',
      left: false,
      right: false,
      width: '200px',
      compare: (a: Inventories, b: Inventories) =>
        a.registered_date.localeCompare(b.registered_date),
      priority: 9,
    },
    {
      name: 'Purchased Date',
      key: 'purchased_date',
      left: false,
      right: false,
      width: '200px',
      compare: (a: Inventories, b: Inventories) => a.purchased_date.localeCompare(b.purchased_date),
      priority: 8,
    },
    {
      name: 'Description',
      key: 'description',
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Depreciation Rate',
      key: 'depreciation_rate',
      left: false,
      right: false,
      width: '200px',
      compare: (a: Inventories, b: Inventories) => a.deprecation_rate - b.deprecation_rate,
      priority: 6,
    },
    {
      name: 'Value',
      key: 'value',
      left: false,
      right: false,
      width: '200px',
      compare: (a: Inventories, b: Inventories) => a.value - b.value,
      priority: 5,
    },
    {
      name: 'Initial Value',
      key: 'initial_value',
      left: false,
      right: false,
      width: '200px',
      compare: (a: Inventories, b: Inventories) => a.initial_value - b.initial_value,
      priority: 4,
    },
    {
      name: 'Created by',
      key: 'created_by',
      left: false,
      right: false,
      width: '200px',
      compare: (a: Inventories, b: Inventories) => a.created_by.localeCompare(b.created_by),
      priority: 3,
    },
    {
      name: 'Created at',
      key: 'created_at',
      left: false,
      right: false,
      width: '200px',
      compare: (a: Inventories, b: Inventories) => a.created_at.localeCompare(b.created_at),
      priority: 2,
    },
    {
      name: 'Updated by',
      key: 'updated_by',
      left: false,
      right: false,
      width: '200px',
      compare: (a: Inventories, b: Inventories) => a.updated_by.localeCompare(b.updated_by),
      priority: 1,
    },
    {
      name: 'Updated at',
      key: 'updated_at',
      left: false,
      right: false,
      width: '200px',
      compare: (a: Inventories, b: Inventories) => a.updated_at.localeCompare(b.updated_at),
      priority: 0,
    },
  ]

  listOfFilter = ['Asset Number', 'Asset Name', 'Employee Name', 'Department', 'Type', 'Location']

  ngOnInit(): void {
    //this.fetchTicket()
    this.filterForm.get('company').setValue(this.selectedCompany)
    this.filterForm.get('filter').setValue(this.listOfFilter[0])
    this.fetchInventories(this.selectedCompany, '', '')
    this.fetchCompay()
    this.autoCompleteEmployee()
    this.filterAutoComplete()
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    // console.log(params)
    const { pageSize, pageIndex, sort } = params
    this.pageSize = pageSize
  }

  fetchInventories(company: string, filter: string, key: string) {
    this.isLoading = true
    this.assetInventoryService.getInventoriesList(company, filter, key).subscribe(r => {
      //console.log(r)
      this.isLoading = false
      this.getAsset = r.rows
      this.totalData = r.count
    })
  }

  filterAutoComplete() {
    this.filterForm
      .get('search')
      .valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(() => this.autoCompleteSearch()),
      )
      .subscribe()
  }

  autoCompleteSearch() {
    var res = []
    if (this.filterForm.value.search.length > 0) {
      // console.log(
      //   this.filterForm.value.company,
      //   this.filterForm.value.filter,
      //   this.filterForm.value.search,
      // )
      this.assetInventoryService
        .searchAutoComplete(
          this.filterForm.value.company,
          this.filterForm.value.filter,
          this.filterForm.value.search,
        )
        .subscribe((r: any[]) => {
          // console.log(r)
          this.searchAutoCompleteList = r
          res = r
        })
    } else {
      this.clearSearch()
    }

    return res
  }

  clearSearch() {
    this.searchAutoCompleteList = []
    this.filterForm.get('search').setValue('')
  }

  searchInventories() {
    this.fetchInventories(
      this.filterForm.value.company,
      this.filterForm.value.filter,
      this.filterForm.value.search,
    )
  }

  fetchCompay() {
    this.assetManagementService.getCompany().subscribe((r: Company[]) => {
      this.companyList = r
    })
  }

  async handleSelectCompany() {
    this.departmentList = []
    this.typeList = []
    this.brandList = []
    this.statusList = []
    this.locationList = []
    this.conditionList = []

    await this.fetchDepartment()
    await this.fetchBrand()
    await this.fetchCondition()
    await this.fetchLocation()
    await this.fetchStatus()
    await this.fetchType()
    await this.fetchVendor()
  }

  autoCompleteEmployee() {
    this.assetsForm
      .get('selectedEmployee')
      .valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(value => this.onChangeEmployee(value)),
      )
      .subscribe()
  }

  onChangeEmployee(value: any): any {
    this.assetsForm.get('phoneNumber').setValue('')
    // console.log(value)
    // console.log(this.assetsForm.value.employeeName)
    if (typeof value === 'object') {
      this.assetsForm.get('employeeName').setValue(value.name)
      this.assetsForm.get('phoneNumber').setValue(value.wa_number.replace('+62', ''))
    }
    if (value.length >= 1) {
      this.assetsForm.get('employeeName').setValue(value)
      this.searchEmployee(value)
    }
    return [value]
  }

  searchEmployee(value: string) {
    const payload = {
      value: value,
      type: 'employeeName',
    }
    // console.log(payload)

    this.assetInventoryService.getEmployee(payload).subscribe((r: Employee[]) => {
      this.employeeList = r
      // console.log(r)
    })
  }

  fetchDepartment() {
    this.assetsForm.get('department').setValue('')
    const company = this.assetsForm.get('company').value
    const config = 'Department'
    this.assetManagementService.getConfigValue(company, config).subscribe((r: ConfigValue[]) => {
      // console.log(r)
      this.departmentList = r
    })
  }

  fetchStatus() {
    this.assetsForm.get('status').setValue('')
    const company = this.assetsForm.get('company').value
    const config = 'Status'
    this.assetManagementService.getConfigValue(company, config).subscribe((r: ConfigValue[]) => {
      // console.log(r)
      this.statusList = r
    })
  }

  fetchType() {
    this.assetsForm.get('type').setValue('')
    const company = this.assetsForm.get('company').value
    const config = 'Type'
    this.assetManagementService.getConfigValue(company, config).subscribe((r: ConfigValue[]) => {
      // console.log(r)
      this.typeList = r
    })
  }

  fetchBrand() {
    this.assetsForm.get('brand').setValue('')
    const company = this.assetsForm.get('company').value
    const config = 'Brand'
    this.assetManagementService.getConfigValue(company, config).subscribe((r: ConfigValue[]) => {
      // console.log(r)
      this.brandList = r
    })
  }

  fetchCondition() {
    this.assetsForm.get('condition').setValue('')
    const company = this.assetsForm.get('company').value
    const config = 'Condition'
    this.assetManagementService.getConfigValue(company, config).subscribe((r: ConfigValue[]) => {
      // console.log(r)
      this.conditionList = r
    })
  }

  fetchLocation() {
    this.assetsForm.get('location').setValue('')
    const company = this.assetsForm.get('company').value
    const config = 'Location'
    this.assetManagementService.getConfigValue(company, config).subscribe((r: ConfigValue[]) => {
      // console.log(r)
      this.locationList = r
    })
  }

  fetchVendor() {
    this.assetsForm.get('vendor').setValue('')
    const company = this.assetsForm.get('company').value
    const config = 'Vendor'
    this.assetManagementService.getConfigValue(company, config).subscribe((r: ConfigValue[]) => {
      // console.log(r)
      this.vendorList = r
    })
  }

  //region add modal action
  handleAddAssetConfirm() {
    if (!this.assetsForm.valid) return

    this.isAddAssetConfirm = true
    setTimeout(() => {
      this.isAddAssetConfirm = false
      this.isAddAssetVisible = false

      const capitalizeWordPipe = new CapitalizeWord()

      const payload: addAsset = {
        employeeName: capitalizeWordPipe.transform(this.assetsForm.value.employeeName),
        department: this.assetsForm.value.department,
        company: this.assetsForm.value.company,
        phoneNumber: this.assetsForm.value.phoneNumber,
        email: this.assetsForm.value.email,
        employeePicture: this.assetsForm.value.employeePicture,
        assetsName: capitalizeWordPipe.transform(this.assetsForm.value.assetName),
        serialNumber: this.assetsForm.value.serialNumber,
        assetNumber: '-',
        status: this.assetsForm.value.status,
        service: this.assetsForm.value.service,
        serviceAt: this.assetsForm.value.serviceAt,
        location: this.assetsForm.value.location,
        type: this.assetsForm.value.type,
        brand: this.assetsForm.value.brand,
        condition: this.assetsForm.value.condition,
        description: this.assetsForm.value.description,
        note: this.assetsForm.value.note,
        purchasedDate: this.assetsForm.value.purchasedDate.toISOString(),
        initialValue: parseInt(
          this.assetsForm.value.initialValue.replace(/\./g, '').replace(',', '.'),
          10,
        ),
        depreciationRate: this.assetsForm.value.depreciationRate,
        period: this.assetsForm.value.period,
        assetPicture: this.assetsForm.value.assetPicture,
        document: this.assetsForm.value.document,
        vendor: this.assetsForm.value.vendor,
      }
      // console.log(payload)
      this.assetInventoryService.addAsset(payload).subscribe(
        r => {
          // console.log(r)
          this.clearForm()
          this.toasterMessage = r.message
          this.toasterIcon = 'check-circle'
          this.toasterColor = '#52c41a'
          this.createBasicNotification(this.toasterTemplate)

          this.fetchInventories(this.filterForm.value.company, '', '')
        },
        error => {
          // console.error(error)
          this.toasterMessage = error.error
          this.toasterIcon = 'warning'
          this.toasterColor = '#eb2f96'
        },
      )
    }, 500)
  }

  clearForm(): void {
    this.assetsForm.get('selectedEmployee').setValue('')
    this.assetsForm.get('email').setValue('')
    this.assetsForm.get('assetName').setValue('')
    this.assetsForm.get('employeeName').setValue('')
    this.assetsForm.get('department').setValue('')
    this.assetsForm.get('employeePicture').setValue('')
    this.assetsForm.get('company').setValue(this.selectedCompany)
    this.assetsForm.get('phoneNumber').setValue('')
    this.assetsForm.get('serialNumber').setValue('')
    this.assetsForm.get('status').setValue('')
    this.assetsForm.get('service').setValue('')
    this.assetsForm.get('serviceAt').setValue('')
    this.assetsForm.get('location').setValue('')
    this.assetsForm.get('assetPicture').setValue('')
    this.assetsForm.get('type').setValue('')
    this.assetsForm.get('brand').setValue('')
    this.assetsForm.get('condition').setValue('')
    this.assetsForm.get('description').setValue('')
    this.assetsForm.get('purchasedDate').setValue('')
    this.assetsForm.get('initialValue').setValue(null)
    this.assetsForm.get('depreciationRate').setValue(null)
    this.assetsForm.get('period').setValue(null)
    this.assetsForm.get('note').setValue('')
    this.assetsForm.get('vendorPICName').setValue('')

    this.assetsForm.get('vendorEmail').setValue('')

    this.assetsForm.get('vendorIndustries').setValue('')

    this.assetsForm.get('vendorPhone').setValue('')

    this.assetPictList = []
    this.employeePictList = []
    this.documentList = []
  }

  formatCurrency(event: any) {
    const inputValue = event.target.value
    const number = inputValue.replace(/[^\d]/g, '')
    // console.log(number)
    const formatedValue = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    })
      .format(number)
      .replace(/^.*\s/, '')
    this.assetsForm.get('initialValue').setValue(formatedValue)
  }

  formatNumber(event: any, formControl) {
    const inputValue = event.target.value
    const number = inputValue.replace(/[^\d.+]/g, '')
    this.assetsForm.get(formControl).setValue(number)
  }

  addInventories() {
    this.isAddAssetVisible = true
  }

  cancelAddInventories() {
    this.isAddAssetVisible = false
    this.employeeList = []
    this.clearForm()
  }

  onChangeVendor(event: any) {
    if (!event) return
    this.assetManagementService
      .getVendorDetail(
        this.assetsForm.value.company
          ? this.assetsForm.value.company
          : this.assetViewForm.value.company,
        event,
      )
      .subscribe(
        (r: vendorDetail) => {
          this.assetsForm.get('vendorPICName').setValue(r.pic_name)
          this.assetsForm.get('vendorPICName').disable()

          this.assetsForm.get('vendorEmail').setValue(r.email)
          this.assetsForm.get('vendorEmail').disable()

          this.assetsForm.get('vendorIndustries').setValue(r.industry)
          this.assetsForm.get('vendorIndustries').disable()

          this.assetsForm.get('vendorPhone').setValue(r.phone_number)
          this.assetsForm.get('vendorPhone').disable()

          this.assetViewForm.get('vendorPICName').setValue(r.pic_name)
          this.assetViewForm.get('vendorPICName').disable()

          this.assetViewForm.get('vendorEmail').setValue(r.email)
          this.assetViewForm.get('vendorEmail').disable()

          this.assetViewForm.get('vendorIndustries').setValue(r.industry)
          this.assetViewForm.get('vendorIndustries').disable()

          this.assetViewForm.get('vendorPhone').setValue(r.phone_number)
          this.assetViewForm.get('vendorPhone').disable()
        },
        error => {
          // console.error(error.error)
        },
      )
  }
  //end region add modal action

  customUpload = (item: NzUploadXHRArgs) => {
    const formData = new FormData()
    formData.append('file', item.file as any)
    this.assetInventoryService.uploadFile(formData).subscribe(
      r => {
        item.onSuccess(r, item.file, r)
        this.toasterMessage = `Upload Success\n${item.file.name}`
        this.toasterIcon = 'check-circle'
        this.toasterColor = '#52c41a'
        this.createBasicNotification(this.toasterTemplate)
      },
      error => {
        item.onError(error, item.file)
        this.toasterMessage = 'Upload Error'
        this.toasterIcon = 'warning'
        this.toasterColor = '#eb2f96'
        this.createBasicNotification(this.toasterTemplate)
      },
    )
  }

  customImport = (item: NzUploadXHRArgs) => {
    const formData = new FormData()
    formData.append('file', item.file as any)
    this.assetInventoryService.importExcel(formData).subscribe(
      r => {
        item.onSuccess(r, item.file, r)
        this.importExcelFile = []
      },
      error => {
        item.onError(error, item.file)
        // console.log(error.error.message)
        this.toasterMessage = error.error.message
        this.toasterIcon = 'warning'
        this.toasterColor = '#eb2f96'
        this.createBasicNotification(this.toasterTemplate)
        this.importExcelFile = []
      },
    )
  }

  getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]): Observable<boolean> =>
    new Observable((observer: Observer<boolean>) => {
      const maxSize = file.type === 'application/pdf' ? 50 * 1024 * 1024 : 2 * 1024 * 1024
      const isLt2M = file.size < maxSize
      console.log(file.size)
      if (!isLt2M) {
        this.toasterMessage = file.type === 'application/pdf' ? 'Pdf Max 50mb' : 'Image Max 2mb'
        this.toasterIcon = 'warning'
        this.toasterColor = '#eb2f96'
        this.createBasicNotification(this.toasterTemplate)
        observer.complete()
        return
      }
      observer.next(isLt2M)
      observer.complete()
    })

  handleChangeEmployee(info: { file: NzUploadFile }): void {
    // console.log(this.employeePictList, 'employee pict list')
    switch (info.file.status) {
      case 'uploading':
        this.loading = true
        break
      case 'done':
        // console.log(info.file.response, 'employee')
        this.assetsForm.get('employeePicture').setValue(info.file.response.name)
        break
      case 'error':
        this.msg.error('Network error')
        this.loading = false
        break
    }
  }

  handleChangeAsset(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true
        break
      case 'done':
        console.log(info.file.response, 'asset')
        const fileList = this.assetsForm.get('assetPicture').value
        console.log(fileList)
        this.assetsForm.get('assetPicture').setValue([...fileList, info.file.response.name])
        break
      case 'error':
        this.msg.error('Network error')
        this.loading = false
        break
    }
  }

  handleChangeImport(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true
        break
      case 'done':
        // console.log(info.file.response, 'import')
        this.toasterMessage = info.file.response.message
        this.toasterIcon = 'check-circle'
        this.toasterColor = '#52c41a'
        this.createBasicNotification(this.toasterTemplate)
        this.fetchInventories(
          this.filterForm.value.company,
          this.filterForm.value.filter,
          this.filterForm.value.search,
        )
        break
      case 'error':
        this.loading = false
        break
    }
  }

  handleChangeDocument(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true
        break
      case 'done':
        // console.log(info.file.response, 'document')
        this.assetsForm.get('document').setValue(info.file.response.name)
        break
      case 'error':
        this.msg.error('Network error')
        this.loading = false
        break
    }
  }

  handleChangeEditDocument(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true
        break
      case 'done':
        // console.log(info.file.response, 'document')
        this.assetViewForm.get('document').setValue(info.file.response.name)
        break
      case 'error':
        this.msg.error('Network error')
        this.loading = false
        break
    }
  }

  handleDownload = (file: NzUploadFile): void => {
    window.open((file.response?.url || file.url) + '?s=download', '_blank')
  }

  handlePreview = async (file: NzUploadFile): Promise<void> => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj!)
    }
    this.previewImage = file.url || file.preview
    this.previewVisible = true
  }

  handleRemove = (file: NzUploadFile) => {
    this.assetInventoryService.deleteFile(file.response?.name || file.name).subscribe(
      r => {
        this.toasterMessage = r.message
        this.toasterIcon = 'check-circle'
        this.toasterColor = '#52c41a'
        this.createBasicNotification(this.toasterTemplate)
        this.fetchInventories(this.filterForm.value.company, '', '')
        if (this.employeePictList[0] === file) this.employeePictList = []
        if (this.assetPictList[0] === file) this.assetPictList = []
        if (this.documentList[0] === file) this.documentList = []
      },
      error => {
        this.toasterMessage = error.error.message
        this.toasterIcon = 'warning'
        this.toasterColor = '#eb2f96'
        this.createBasicNotification(this.toasterTemplate)
        if (this.employeePictList[0] === file) this.employeePictList = []
        if (this.assetPictList[0] === file) this.assetPictList = []
        if (this.documentList[0] === file) this.documentList = []
      },
    )
  }

  deleteAsset(asset: Inventories) {
    // console.log(asset.asset_number)
    this.confirmModal = this.modal.confirm({
      nzWidth: '500px',
      nzTitle: `Do you want to delete ${asset.asset_number} asset?`,
      nzContent:
        'After clicked the OK button, everything associate with this asset will be removed!',
      nzOnOk: () => {
        setTimeout(() => {
          this.assetInventoryService.deleteAsset(asset.asset_number).subscribe(
            r => {
              // console.log(r)
              this.toasterMessage = r.message
              this.toasterIcon = 'check-circle'
              this.toasterColor = '#52c41a'
              this.createBasicNotification(this.toasterTemplate)
              this.fetchInventories(this.filterForm.value.company, '', '')
            },
            error => {
              // console.error(error.error.message)
              this.toasterMessage = error.error.message
              this.toasterIcon = 'warning'
              this.toasterColor = '#eb2f96'
              this.createBasicNotification(this.toasterTemplate)
            },
          )
        }, 500)
      },
    })
  }

  downloadBarcode(asset: Inventories) {
    const docDefinition = {
      pageSize: {
        width: 215,
        height: 425,
      },
      pageMargin: [0, 5],
      content: [
        {
          alignment: 'center',
          qr: asset.asset_number.toLocaleLowerCase(),
          fit: 90,
        },
        {
          alignment: 'center',
          margin: [0, 2],
          text: `${asset.asset_number}`,
          fontSize: 8,
        },
      ],
    }
    const pdfDoc = pdfMake.createPdf(docDefinition)

    pdfDoc.open()
  }

  massDownloadBarcode() {
    console.log(this.getAsset)
    let dataTable = this.getAsset
      .map(item => [
        {
          alignment: 'center',
          qr: item.asset_number.toLocaleLowerCase(),
          fit: 90,
          margin: [0, 5],
        },
        {
          alignment: 'center',
          margin: [0, 2],
          text: `${item.asset_number}`,
          fontSize: 8,
        },
        {
          alignment: 'center',
          margin: [0, 20],
          text: '',
          fontSize: 8,
        },
      ])
      .flat()

    console.log(dataTable)

    // for (let i = 0; i < this.totalData; i++) {
    //   if (i % 1 === 0) {
    //     dataTable.push([''])
    //   }
    //   const rowIndex = Math.floor(i / 1)
    //   const colIndex = i % 1

    //   dataTable[rowIndex][colIndex] = [
    //     {
    //       margin: [0, 2],
    //       qr: this.getAsset[i].asset_number.toLocaleLowerCase(),
    //       alignment: 'center',
    //       fit: 90,
    //     },
    //     {
    //       alignment: 'center',
    //       margin: [0, 2],
    //       text: this.getAsset[i].asset_number,
    //       fontSize: 8,
    //     },
    //   ]
    // }

    // let lastIndex = dataTable.length - 1
    // let lastIndexLength = 1 - dataTable[lastIndex].length
    // if (lastIndexLength) {
    //   for (let i = 0; i < lastIndexLength; i++) {
    //     dataTable[lastIndex].push([{ text: '' }])
    //   }
    // }
    // console.log(lastIndexLength)

    const docDefinition = {
      pageSize: {
        width: 215,
        height: 425,
      },
      content: dataTable,
      // content: [
      //   {
      //     table: {
      //       widths: [120],
      //       body: dataTable,
      //     },
      //     // layout: 'noBorders',
      //   },
      // ],
    }

    const pdfDoc = pdfMake.createPdf(docDefinition)

    pdfDoc.open()
  }

  exportAssets() {
    this.isDownloading = true
    var blankPage = window.open('http://example.com/waiting.html', '_blank')
    blankPage!.document.write('Loading preview...')
    // console.log(
    //   this.filterForm.value.company,
    //   this.filterForm.value.filter,
    //   this.filterForm.value.search,
    // )
    this.assetInventoryService
      .exportAssets(
        this.filterForm.value.company,
        this.filterForm.value.filter,
        this.filterForm.value.search,
      )
      .subscribe(
        r => {
          // console.log(r.link)
          this.isDownloading = false
          blankPage!.location.href = r.link
          this.toasterMessage = r.message
          this.toasterIcon = 'check-circle'
          this.toasterColor = '#52c41a'
          this.createBasicNotification(this.toasterTemplate)
        },
        err => {
          blankPage.close()
          this.isDownloading = false
          this.toasterMessage = err.error.message
          this.toasterIcon = 'warning'
          this.toasterColor = '#eb2f96'
          this.createBasicNotification(this.toasterTemplate)
        },
      )
  }

  //view
  async viewAsset(asset: Inventories) {
    this.isViewLoading = true
    this.isViewVisible = true
    const config = 'Vendor'
    this.selectedAsset = asset
    await this.assetManagementService
      .getConfigValue(asset.company, config)
      .subscribe((r: ConfigValue[]) => {
        // console.log(r)
        this.vendorList = r
      })
    // console.log(asset)
    if (asset.employee_picture) {
      this.viewEmployeePicture = [
        {
          uid: '-1',
          name: asset.employee_picture,
          status: 'done',
          url: `${this.apiService.API_SUNSHINE_SERVER}/api/assetManagement/inventories/upload/${asset.employee_picture}`,
        },
      ]
    }

    // this.viewAssetPicture = [
    //   {
    //     uid: '-1',
    //     name: asset.assets_picture,
    //     status: 'done',
    //     url: `${this.apiService.API_SUNSHINE_SERVER}/api/assetManagement/inventories/upload/${asset.assets_picture}`,
    //   },
    // ]
    if (asset.asset_pictures) {
      this.viewAssetPicture = asset.asset_pictures.map((item, i) => ({
        uid: `${i}`,
        name: item.filename,
        status: 'done',
        url: `${this.apiService.API_SUNSHINE_SERVER}/api/assetManagement/inventories/upload/${item.filename}`,
      }))
    }

    if (asset.document) {
      this.documentList = [
        {
          uid: '-1',
          name: asset.document,
          status: 'done',
          url: `${this.apiService.API_SUNSHINE_SERVER}/api/assetManagement/inventories/upload/${asset.document}`,
        },
      ]
    } else {
      this.documentList = []
    }

    this.assetViewForm.get('employeeName').setValue(asset.employee_name)
    this.assetViewForm.get('company').setValue(asset.company)
    this.assetViewForm.get('department').setValue(asset.department)
    this.assetViewForm.get('phoneNumber').setValue(asset.phone_number)
    this.assetViewForm.get('email').setValue(asset.email)
    this.assetViewForm.get('assetName').setValue(asset.asset_name)
    this.assetViewForm.get('serialNumber').setValue(asset.serial_number)
    this.assetViewForm.get('purchasedDate').setValue(new Date(asset.purchased_date))
    this.assetViewForm.get('status').setValue(asset.status)
    this.assetViewForm.get('service').setValue(asset.service)
    this.assetViewForm.get('serviceAt').setValue(asset.service_at)
    this.assetViewForm.get('location').setValue(asset.location)
    this.assetViewForm.get('type').setValue(asset.type)
    this.assetViewForm.get('brand').setValue(asset.brand)
    this.assetViewForm.get('condition').setValue(asset.condition)
    this.assetViewForm.get('description').setValue(asset.description)
    this.assetViewForm.get('vendor').setValue(asset.vendor)
    this.assetViewForm.get('vendorPICName').setValue(asset.vendorDetail.pic_name)
    this.assetViewForm.get('vendorEmail').setValue(asset.vendorDetail.email)
    this.assetViewForm.get('vendorIndustries').setValue(asset.vendorDetail.industry)
    this.assetViewForm.get('vendorPhone').setValue(asset.vendorDetail.phone_number)
    this.assetViewForm
      .get('initialValue')
      .setValue(
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' })
          .format(asset.initial_value)
          .replace(/^.*\s/, ''),
      )
    this.assetViewForm.get('depreciationRate').setValue(asset.deprecation_rate)
    this.assetViewForm.get('period').setValue(asset.period)
    this.assetViewForm.get('note').setValue(asset.note)

    this.assetViewForm.get('assetName').enable()
    this.assetViewForm.get('serialNumber').enable()

    // Disable all other fields
    this.assetViewForm.get('employeeName').disable()

    this.assetViewForm.get('phoneNumber').disable()
    this.assetViewForm.get('email').disable()
    this.assetViewForm.get('purchasedDate').disable()
    this.assetViewForm.get('status').disable()
    this.assetViewForm.get('service').disable()
    this.assetViewForm.get('serviceAt').disable()
    this.assetViewForm.get('description').disable()
    this.assetViewForm.get('initialValue').disable()
    this.assetViewForm.get('depreciationRate').disable()
    this.assetViewForm.get('period').disable()
    this.assetViewForm.get('note').disable()
    this.isViewLoading = false
  }

  confirmUpdate() {
    this.isUpdateAssetConfirm = true

    const capitalizeWordPipe = new CapitalizeWord()
    const editedData = {}

    // Compare and add edited data to editedData object
    editedData['assetNumber'] = this.selectedAsset.asset_number
    if (this.assetViewForm.value.assetName !== this.selectedAsset.asset_name) {
      editedData['assetName'] = capitalizeWordPipe.transform(this.assetViewForm.value.assetName)
    }

    if (this.assetViewForm.value.serialNumber !== this.selectedAsset.serial_number) {
      editedData['serialNumber'] = this.assetViewForm.value.serialNumber
    }

    if (this.assetViewForm.value.document !== this.selectedAsset.document) {
      editedData['document'] = this.assetViewForm.value.document
    }

    if (this.assetViewForm.value.vendor !== this.selectedAsset.vendor) {
      editedData['vendor'] = this.assetViewForm.value.vendor
    }

    setTimeout(() => {
      this.isViewVisible = false
      this.isUpdateAssetConfirm = false
      this.assetInventoryService.editAsset(editedData).subscribe(
        r => {
          // console.log(r)
          this.toasterMessage = r.message
          this.toasterIcon = 'check-circle'
          this.toasterColor = '#52c41a'
          this.createBasicNotification(this.toasterTemplate)
          this.clearForm()
          this.fetchInventories(
            this.filterForm.value.company,
            this.filterForm.value.filter,
            this.filterForm.value.search,
          )
        },
        error => {
          // console.error(error)
          this.toasterMessage = error.error.message
          this.toasterIcon = 'warning'
          this.toasterColor = '#eb2f96'
          this.createBasicNotification(this.toasterTemplate)
        },
      )
    }, 500)
  }

  closeViewAsset() {
    this.isViewVisible = false
    this.viewAssetPicture = []
    this.viewEmployeePicture = []
    this.documentList = []
  }

  createBasicNotification(template: TemplateRef<{}>): void {
    this.notification.template(template, {
      nzPlacement: 'bottomRight',
    })
  }
}
