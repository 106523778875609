import { Component, OnInit, ViewContainerRef } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { NzModalService } from 'ng-zorro-antd/modal'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzTableFilterList, NzTableQueryParams, NzTableSortOrder } from 'ng-zorro-antd/table'
import { freelanceService } from 'src/app/services/freelance-agent.service'
import { Freelance } from 'src/app/services/interface/freelance-agent.model'
import { QueryTable } from 'src/app/services/interface/general.model'
import { AddFreelanceAgentComponent } from './add-freelance-agent/add-freelance-agent.component'
import { EditFreelanceAgentComponent } from './edit-freelance-agent/edit-freelance-agent.component'

interface ColumnItem {
  name: string
  key: string
  sortOrder: NzTableSortOrder | null
  sortFn: null | boolean
  listOfFilter: NzTableFilterList
  filterFn: null
  left: boolean
  right: boolean
  width: string
}

@Component({
  selector: 'app-freelance-agent',
  templateUrl: './freelance-agent.component.html',
  styleUrls: ['./freelance-agent.component.scss'],
})
export class FreelanceAgentComponent implements OnInit {
  listOfData: Freelance[] = []

  isLoading: boolean = true
  totalData: number = 0
  pageSize: number = 25
  pageIndex: number = 1
  form: FormGroup

  statusFiltered: string = 'Total'
  isDownloading: boolean = false
  constructor(
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private freelanceService: freelanceService,
    private notification: NzNotificationService,
  ) {}

  queryTable: QueryTable = {
    sort: null,
    filters: [],
    pageSize: this.pageSize,
    pageIndex: this.pageIndex - 1,
  }

  listOfColumns: ColumnItem[] = [
    {
      name: 'Actions',
      key: 'actions',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      left: true,
      right: false,
      width: '100px',
    },
    {
      name: 'Name',
      key: 'name',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '150px',
    },
    {
      name: 'Email',
      key: 'email',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '200px',
    },
    {
      name: 'Created By',
      key: 'createdBy',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '130px',
    },
    {
      name: 'Created At',
      key: 'createdAt',
      sortOrder: null,
      sortFn: true,
      listOfFilter: [],
      filterFn: null,
      left: false,
      right: false,
      width: '130px',
    },
  ]

  ngOnInit(): void {
    this.fetchFreelance()
  }

  fetchFreelance() {
    this.isLoading = true
    this.freelanceService.getFreelance(this.queryTable).subscribe((r: any) => {
      this.isLoading = false
      this.listOfData = r.rows
      this.totalData = r.count
    })
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    // console.log(params)
    // console.log(this.pageIndex, this.pageSize)
    const { pageSize, pageIndex, sort, filter } = params
    this.queryTable.pageSize = pageSize
    this.queryTable.pageIndex = pageIndex - 1
    const currentSort = sort.find(item => item.value !== null)
    const sortField = (currentSort && currentSort.key) || null
    const sortOrder = (currentSort && currentSort.value) || null

    if (sortField != null && sortOrder != null) {
      this.queryTable.sort = {
        field: sortField,
        order: sortOrder,
      }
    } else {
      this.queryTable.sort = null
    }
    this.fetchFreelance()
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name
  }

  addFreelance() {
    const modal = this.modal.create({
      nzTitle: 'Add New Freelance Agent',
      nzContent: AddFreelanceAgentComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {},
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Create',
      nzOkDisabled: true,
      // nzOnCancel: () => {
      //   console.log('CANCEL')
      // },
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          instance.submitForm()
        }),
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchFreelance()
    })
  }
  // openImage(filename: string) {
  //   console.log(filename)
  //   this.previewImage = this.apiService.API_SUNSHINE_SERVER + '/api/cms/banner/' + filename
  //   console.log(this.previewImage)
  //   this.previewVisible = true
  // }
  viewFreelance(id: string) {
    const modal = this.modal.create({
      nzTitle: 'View Freelance Agent',
      nzContent: EditFreelanceAgentComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        id: id,
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzOkText: 'Update',
      nzOkDisabled: true,
      // nzOnCancel: () => {
      //   console.log('CANCEL')
      // },
      nzOnOk: () => {
        // new Promise((resolve, reject) => {
        //   if (instance.submitForm()) {
        //     resolve
        //   } else {
        //     reject
        //   }
        // }),
        console.log('OK')
      },
      nzWidth: '75%',
    })
    const instance = modal.getContentComponent()
    modal.afterClose.subscribe(() => {
      this.fetchFreelance()
    })
  }
}
