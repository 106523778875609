import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-dialog-decision',
  templateUrl: './dialog-decision.component.html',
  // styleUrls: ['./dialog-decision.component.scss'],
})
export class DialogDecisionComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private loadingDialogRef: MatDialogRef<DialogDecisionComponent>,
  ) {}

  ngOnInit(): void {}
}
