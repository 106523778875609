<form nz-form [formGroup]="form">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <nz-form-item>
          <nz-form-label nzFor="name" class="text-left">Name</nz-form-label>
          <nz-form-control nzErrorTip="Please input a name">
            <input nz-input id="name"  formControlName="name" placeholder="Input name ..." />
          </nz-form-control>
        </nz-form-item>
      </div>
  
      <!-- <div class="col-lg-6 col-md-12">
        <nz-form-item>
          <nz-form-label nzFor="phone" class="text-left">Phone No.</nz-form-label>
          <nz-form-control nzErrorTip="Please input a valid number">
            <input nz-input id="phone" type="number" formControlName="phone" placeholder="Input phone no. ..." />
          </nz-form-control>
        </nz-form-item>
      </div> -->

      <div class="col-lg-6 col-md-12">
        <nz-form-item>
          <nz-form-label nzFor="phone">Phone Number</nz-form-label>
          <nz-form-control nzErrorTip="Please input a valid phone number">
            <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
              <ng-template #addOnBeforeTemplate>
                <nz-select formControlName="phonePrefix" class="phone-select">
                  <nz-option nzLabel="+62" nzValue="+62"></nz-option>
                </nz-select>
              </ng-template>
              <input formControlName="phone" id="'phone'" nz-input />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div class="col-6">
        <nz-form-item>
          <nz-form-label nzFor="email" class="text-left">Email</nz-form-label>
          <nz-form-control nzErrorTip="Please input a valid email">
            <input nz-input id="email" type="email" formControlName="email" placeholder="Input email ..." />
          </nz-form-control>
        </nz-form-item>
      </div>
  
      <!-- <div class="col-6">
        <nz-form-item>
          <nz-form-label nzFor="meetingRoom" class="text-left">Meeting Room</nz-form-label>
          <nz-form-control nzErrorTip="Please select an available meeting room">
            <nz-select formControlName="meetingRoom" nzPlaceHolder="Choose Meeting Room">
              <nz-option *ngFor="let item of roomArr" [nzLabel]="item.name" [nzValue]="item.name"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div> -->
  
      <!-- <div class="col-6">
        <nz-form-item>
          <nz-form-label nzFor="division" class="text-left">Division</nz-form-label>
          <nz-form-control nzErrorTip="Please select available division">
            <nz-select formControlName="division" nzPlaceHolder="Choose Division">
              <nz-option *ngFor="let item of divArr" [nzLabel]="item.name" [nzValue]="item.id"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div> -->
  
      <!-- <div class="col-6">
        <nz-form-item>
          <nz-form-label nzFor="agenda" class="text-left">Function</nz-form-label>
          <nz-form-control nzErrorTip="Please input valid function">
            <nz-textarea-count>
              <textarea rows="4" formControlName="agenda" nz-input></textarea>
            </nz-textarea-count>
          </nz-form-control>
        </nz-form-item>
      </div>
  
      <div class="col-6">
        <nz-form-item>
          <nz-form-label nzFor="timeStart" class="text-left">Start Time</nz-form-label>
          <nz-form-control nzErrorTip="Please input valid time">
            <nz-time-picker formControlName="timeStart" nzFormat="HH:mm"></nz-time-picker>
          </nz-form-control>
          <nz-form-label nzFor="timeEnd" class="text-left">End Time</nz-form-label>
          <nz-form-control nzErrorTip="Please input valid time">
            <nz-time-picker formControlName="timeEnd" nzFormat="HH:mm"></nz-time-picker>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label nzFor="date_start" class="text-left">Start Date</nz-form-label>
          <nz-form-control>
            <nz-date-picker formControlName="dateStart" [nzDisabledDate]="disabledDateStart" nzFormat="dd-MM-yyyy"
            ></nz-date-picker>
          </nz-form-control>
          <nz-form-label nzFor="date_end" class="text-left">End Date</nz-form-label>
          <nz-form-control nzErrorTip="Please input valid date">
            <nz-date-picker formControlName="dateEnd" [nzDisabledDate]="disabledDateEnd" nzFormat="dd-MM-yyyy"
            ></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
  
      <div class="col-6">
        <nz-form-item>
          <nz-form-label nzFor="colour" class="text-left">Colour</nz-form-label>
          <nz-form-control nzErrorTip="Please select available colour">
            <nz-select formControlName="colour" nzPlaceHolder="Choose Colour">
              <nz-option *ngFor="let item of colourArr" [nzLabel]="item.name" [nzValue]="item.id"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div> -->
    </div>
  </form>