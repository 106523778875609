import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http'
import { ApiService } from './api.service'
import { BehaviorSubject, Observable } from 'rxjs'
import store from 'store'
import { ShiftForm } from './interface/shift.model'
import { QueryTable } from './interface/general.model'

@Injectable({
  providedIn: 'root',
})
export class ShiftService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  createShift(form: any): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/shift/create', form, params)
  }

  getShift(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/shift/list',
      queryTable,
      params,
    )
  }

  detailShift(id: any): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id: id,
          },
        }
      : {}
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/shift/detail', params)
  }

  updateShift(form: ShiftForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/shift/update', form, params)
  }

  deleteShift(id: any): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id: id,
          },
        }
      : {}
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/shift/delete', params)
  }

  // _______ EMPLOYEE SHIFT _______ //

  getEmpShift(queryTable: QueryTable): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/employee-shift/list',
      queryTable,
      params,
    )
  }
}
