import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { BehaviorSubject, Observable } from 'rxjs'
import store from 'store'
import { QueryTable } from './interface/general.model'
import { AdForm } from './interface/ad.model'

@Injectable({
  providedIn: 'root',
})
export class freelanceService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  createFreelance(form: any): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/freelance/add', form, params)
  }

  getFreelance(queryTable: any): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(
      this.apiService.API_SUNSHINE_SERVER + '/api/freelance/viewlist',
      queryTable,
      params,
    )
  }

  detailFreelance(id: any): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            id: id,
          },
        }
      : {}
    return this.http.get(this.apiService.API_SUNSHINE_SERVER + '/api/freelance/viewdetail', params)
  }

  updateFreelance(form: AdForm): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/cms/ad/update', form, params)
  }

  deleteAd(id: any): Observable<any> {
    const accessToken = store.get('accessToken')
    const params = accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : {}
    const body = {
      id: id,
    }
    return this.http.post(this.apiService.API_SUNSHINE_SERVER + '/api/cms/ad/delete', body)
  }
}
