<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">

        <nz-table #sortTable [nzData]="getAsset" [nzLoading]="isLoading" [nzTotal]="totalData" [nzPageSize]="pageSize"
          [nzPageIndex]="pageIndex" [nzPageSizeOptions]="[25, 50, 100, 250]" [nzScroll]="{ y: '50vh'}" nzShowSizeChanger
          [nzTitle]="headerTable" nzSize="small" (nzQueryParams)="onQueryParamsChange($event)">
          <thead>
            <tr>
              <ng-container *ngFor="let column of listOfColumns">
                <th [nzWidth]="column.width" [nzSortFn]="column.compare" [nzSortPriority]="column.priority">
                  {{ column.name }}
                </th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of sortTable.data">
              <td>
                <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="View Asset">
                  <button class="btn btn-sm btn-light mr-2" (click)='viewAsset(data)'>
                    <i class="fe fe-eye"></i>
                  </button>
                </span>
                <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Delete Asset">
                  <button class="btn btn-sm btn-light mr-2" (click)="deleteAsset(data)">
                    <i class="fe fe-trash-2"></i>
                  </button>
                </span>
                <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Download Barcode">
                  <button class="btn btn-sm btn-light mr-2" (click)="downloadBarcode(data)">
                    <i class="fe fe-download"></i>
                  </button>
                </span>
                <span style="margin: 0 2px;"></span>
              </td>
              <td>{{data.asset_number}}</td>
              <td>{{data.asset_name}}</td>
              <td>{{data.employee_name}}</td>
              <td>{{data.department}}</td>
              <td>{{data.type}}</td>
              <td>{{data.serial_number}}</td>
              <td>{{data.status}}</td>
              <td>{{data.condition}}</td>
              <td>{{data.brand}}</td>
              <td style="max-width: 200px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                {{data.note}}</td>
              <td>{{data.service}}</td>
              <td>{{data.service_at}}</td>
              <td>{{data.location}}</td>
              <td>{{data.registered_date| date:'yyyy-MM-dd HH:mm:ss'}}</td>
              <td>{{data.purchased_date| date:'yyyy-MM-dd'}}</td>
              <td style="max-width: 200px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                {{data.description}}</td>
              <td>{{data.deprecation_rate}}%</td>
              <td>{{data.value | currency:"RP":"symbol":"1.2-2" }}</td>
              <td>{{data.initial_value | currency:"RP":"symbol":"1.2-2"}}</td>
              <td>{{data.created_by}}</td>
              <td>{{data.created_at| date:'yyyy-MM-dd HH:mm:ss'}}</td>
              <td>{{data.updated_by}}</td>
              <td>{{data.updated_at| date:'yyyy-MM-dd HH:mm:ss'}}</td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>

<!-- <ng-template #tplFooter let-ref="modalRef">
  <button nz-button (click)="ref.destroy()" [disabled]="ref.getContentComponent().isLoading" nzDanger>
    Cancel
  </button>
  <button nz-button nzType="primary" (click)="ref.getContentComponent().submitForm()"
    [nzLoading]="ref.getContentComponent().isLoading">
    Create
  </button>
</ng-template> -->

<ng-template #headerTable>
  <nz-spin [nzSpinning]="isDownloading">
    <div class="row justify-content-between">
      <div class="col-4">
        <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Add Asset" (click)="addInventories()">
          <a class="btn btn-lg btn-light mr-2">
            <i class="fe fe-plus-circle"></i>
          </a>
        </span>
        <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Refresh">
          <a class="btn btn-lg btn-light mr-2" (click)="ngOnInit()">
            <i class="fe fe-refresh-cw"></i>
          </a>
        </span>
        <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Download QR">
          <a class="btn btn-lg btn-light mr-2" (click)="massDownloadBarcode()">
            <span nz-icon nzType="qrcode" nzTheme="outline"></span>
          </a>
        </span>
        <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Export Assets">
          <a class="btn btn-lg btn-light mr-2" (click)="exportAssets()">
            <i class="fe fe-download"></i>
          </a>
        </span>
        <nz-upload [nzCustomRequest]="customImport" [(nzFileList)]="importExcelFile"
          (nzChange)="handleChangeImport($event) ">
          <span nzTooltipPlacement="top" nz-tooltip nzTooltipTitle="Upload">
            <a class="btn btn-lg btn-light mr-2">
              <i class="fe fe-upload"></i>
            </a>
          </span>
        </nz-upload>
      </div>

      <div class="col">
        <form nz-form [nzLayout]="'inline'" [formGroup]="filterForm" (ngSubmit)="searchInventories()"
          style="display: flex; flex-direction: row; justify-content: flex-end;">

          <nz-form-item>
            <nz-form-control>
              <nz-select id="company" style="width: 130px;" formControlName="company"
                (ngModelChange)="searchInventories()">
                <ng-container *ngFor="let item of companyList">
                  <nz-option [nzValue]="item.value" [nzLabel]="item.value"></nz-option>
                </ng-container>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control>
              <nz-select id="filter" style="width: 130px;" formControlName="filter" (ngModelChange)="clearSearch()">
                <ng-container *ngFor="let item of listOfFilter">
                  <nz-option [nzValue]="item" [nzLabel]="item"></nz-option>
                </ng-container>
              </nz-select>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control>
              <nz-input-group id="search" style="width: 150px;">
                <input type="text" nz-input placeholder="Search" style=" border-radius: 8px;" formControlName="search"
                  [nzAutocomplete]="filter" />
                <nz-autocomplete class="autocomplete" [nzDataSource]="searchAutoCompleteList" nzBackfill
                  #filter></nz-autocomplete>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control>
              <button type="submit" class="btn btn-primary">
                Search
              </button>
            </nz-form-control>
          </nz-form-item>
        </form>
      </div>
    </div>
  </nz-spin>
</ng-template>

<nz-modal [(nzVisible)]="isAddAssetVisible" [nzWidth]="'80%'" [nzTitle]="addModalTitle" [nzContent]="addModalContent"
  [nzFooter]="addModalFooter" (nzOnCancel)="cancelAddInventories()">

  <ng-template #addModalTitle> Add Asset</ng-template>

  <ng-template #addModalContent>
    <form nz-form [formGroup]="assetsForm">
      <nz-collapse>
        <nz-collapse-panel [nzHeader]="'Employee Data'" [nzActive]="true">

          <div class="row">
            <div class="col">
              <nz-form-item>
                <nz-form-label nzFor="employee_name" class="text-left col-4">Employee Name</nz-form-label>
                <nz-form-control>
                  <input id="employee_name" type="text" nz-input style=" border-radius: 8px;"
                    formControlName="selectedEmployee" [nzAutocomplete]="employee" />
                  <nz-autocomplete class="autocomplete" nzBackfill #employee>
                    <nz-auto-option *ngFor="let item of employeeList" [nzValue]="item"
                      [nzLabel]="item.name">{{item.name}}</nz-auto-option>
                  </nz-autocomplete>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label nzFor="department" class="text-left col-4">Department</nz-form-label>
                <nz-form-control>
                  <nz-select id="department" formControlName="department"
                    [nzDisabled]="assetsForm.get('company').value.length<1">
                    <ng-container *ngFor="let item of departmentList">
                      <nz-option [nzValue]="item.value" [nzLabel]="item.value"></nz-option>
                    </ng-container>
                  </nz-select>
                  <!-- <input id="department" type="text" nz-input style=" border-radius: 8px;"
                    [disabled]="assetsForm.get('company').value.length<1" /> -->
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label class="text-left col-4">Employee Picture</nz-form-label>
                <nz-form-control>
                  <nz-upload nzName="picture" nzListType="picture-card" [nzLimit]="1" [nzAccept]="'.png, .jpg, .jpeg'"
                    [(nzFileList)]="employeePictList" [nzCustomRequest]="customUpload" [nzPreview]="handlePreview"
                    [nzBeforeUpload]="beforeUpload" (nzChange)="handleChangeEmployee($event) "
                    [nzDownload]="handleDownload" [nzRemove]="handleRemove"
                    [nzAccept]="'image/jpeg, image/jpg, image/png'" [nzDisabled]="employeePictList.length>0"
                    [nzShowButton]="!(employeePictList.length>0)">
                    <div>
                      <span nz-icon nzType="plus"></span>
                      <div style="margin-top: 8px">Upload</div>
                    </div>
                  </nz-upload>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div class="col">
              <nz-form-item>
                <nz-form-label nzFor="company" class="text-left col-4">Company</nz-form-label>
                <nz-form-control>
                  <nz-select id="company" formControlName="company" (ngModelChange)="handleSelectCompany()">
                    <ng-container *ngFor="let item of companyList">
                      <nz-option [nzValue]="item.value" [nzLabel]="item.value"></nz-option>
                    </ng-container>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label nzFor="phone_number" class="text-left col-4">Phone Number</nz-form-label>
                <nz-form-control>
                  <input id="phone_number" formControlName="phoneNumber" type="text" nz-input
                    style=" border-radius: 8px;" />
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label nzFor="email" class="text-left col-4">Email</nz-form-label>
                <nz-form-control>
                  <input id="email" type="text" nz-input style=" border-radius: 8px;" formControlName="email" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

        </nz-collapse-panel>

        <nz-collapse-panel [nzHeader]="'Asset Data'" [nzActive]="false">
          <div class="row">
            <div class="col">
              <nz-form-item>
                <nz-form-label nzFor="asset_name" class="text-left col-4">Asset Name</nz-form-label>
                <nz-form-control>
                  <input id="asset_name" type="text" nz-input style=" border-radius: 8px;"
                    formControlName="assetName" />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzFor="serial_number" class="text-left col-4">Serial Number</nz-form-label>
                <nz-form-control>
                  <input id="serial_number" type="text" nz-input style=" border-radius: 8px;"
                    formControlName="serialNumber" />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzFor="purchased_date" class="text-left col-4">Purchased Date</nz-form-label>
                <nz-form-control>
                  <nz-date-picker formControlName="purchasedDate"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label nzFor="status" class="text-left col-4">Status</nz-form-label>
                <nz-form-control>
                  <nz-select id="status" formControlName="status"
                    [nzDisabled]="assetsForm.get('company').value.length<1">
                    <ng-container *ngFor="let item of statusList">
                      <nz-option [nzValue]="item.value" [nzLabel]="item.value"></nz-option>
                    </ng-container>
                  </nz-select>
                  <!-- <input id="status" type="text" nz-input style=" border-radius: 8px;" /> -->
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzFor="service" class="text-left col-4">Service</nz-form-label>
                <nz-form-control>
                  <input id="service" type="text" nz-input style=" border-radius: 8px;" formControlName="service"
                    (input)="formatNumber($event,'service')" />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzFor="serviceAt" class="text-left col-4">Service At</nz-form-label>
                <nz-form-control>
                  <input id="serviceAt" type="text" nz-input style=" border-radius: 8px;color:black"
                    formControlName="serviceAt" numbersOnly />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzFor="location" class="text-left col-4">Location</nz-form-label>
                <nz-form-control>
                  <nz-select id="location" formControlName="location"
                    [nzDisabled]="assetsForm.get('company').value.length<1">
                    <ng-container *ngFor="let item of locationList">
                      <nz-option [nzValue]="item.value" [nzLabel]="item.value"></nz-option>
                    </ng-container>
                  </nz-select>
                  <!-- <input id="location" type="text" nz-input style=" border-radius: 8px;" /> -->
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label class="text-left col-4">Asset Picture</nz-form-label>
                <nz-form-control>
                  <nz-upload nzName="file" nzListType="picture-card" [nzShowUploadList]="true"
                    [nzAccept]="'.png, .jpg, .jpeg'" [nzMultiple]="false" [(nzFileList)]="assetPictList" [nzLimit]="1"
                    [nzCustomRequest]="customUpload" [nzPreview]="handlePreview" [nzDownload]="handleDownload"
                    [nzRemove]="handleRemove" [nzBeforeUpload]="beforeUpload" (nzChange)="handleChangeAsset($event)"
                    [nzDisabled]="assetPictList.length>20" [nzShowButton]="!(assetPictList.length>20)">
                    <div>
                      <span nz-icon nzType="plus"></span>
                      <div style="margin-top: 8px">Upload</div>
                    </div>
                  </nz-upload>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label class="text-left col-4">Document</nz-form-label>
                <nz-form-control>
                  <nz-upload nzName="file" nzListType="picture-card" [nzAccept]="'.pdf, image/jpeg, image/png'"
                    [nzShowUploadList]="true" [nzMultiple]="false" [(nzFileList)]="documentList" [nzLimit]="1"
                    [nzCustomRequest]="customUpload" [nzPreview]="handlePreview" [nzDownload]="handleDownload"
                    [nzRemove]="handleRemove" [nzBeforeUpload]="beforeUpload" (nzChange)="handleChangeDocument($event)"
                    [nzDisabled]="documentList.length>0" [nzShowButton]="!(documentList.length>0)">
                    <div>
                      <span nz-icon nzType="plus"></span>
                      <div style="margin-top: 8px">Upload</div>
                    </div>
                  </nz-upload>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div class="col">

              <nz-form-item>
                <nz-form-label nzFor="type" class="text-left col-4">Type</nz-form-label>
                <nz-form-control>
                  <nz-select id="type" formControlName="type" [nzDisabled]="assetsForm.get('company').value.length<1">
                    <ng-container *ngFor="let item of typeList">
                      <nz-option [nzValue]="item.value" [nzLabel]="item.value"></nz-option>
                    </ng-container>
                  </nz-select>
                  <!-- <input id="type" type="text" nz-input style=" border-radius: 8px;" /> -->
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzFor="brand" class="text-left col-4">Brand</nz-form-label>
                <nz-form-control>
                  <nz-select id="brand" formControlName="brand" [nzDisabled]="assetsForm.get('company').value.length<1">
                    <ng-container *ngFor="let item of brandList">
                      <nz-option [nzValue]="item.value" [nzLabel]="item.value"></nz-option>
                    </ng-container>
                  </nz-select>
                  <!-- <input id="brand" type="text" nz-input style=" border-radius: 8px;" /> -->
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzFor="condition" class="text-left col-4">Condition</nz-form-label>
                <nz-form-control>
                  <nz-select id="condition" formControlName="condition"
                    [nzDisabled]="assetsForm.get('company').value.length<1">
                    <ng-container *ngFor="let item of conditionList">
                      <nz-option [nzValue]="item.value" [nzLabel]="item.value"></nz-option>
                    </ng-container>
                  </nz-select>
                  <!-- <input id="condition" type="text" nz-input style=" border-radius: 8px;" /> -->
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzFor="description" class="text-left col-4">Description</nz-form-label>
                <nz-form-control>
                  <textarea id="description" nz-input style=" border-radius: 8px;height: 100px;"
                    formControlName="description"></textarea>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzFor="initial_value" class="text-left col-4">Initial Value</nz-form-label>
                <nz-form-control>
                  <nz-input-group nzPrefix="Rp.">
                    <input id="initial_value" type="text" nz-input formControlName="initialValue"
                      (input)="formatCurrency($event)" style="border-radius: 8px;" numbersOnly />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzFor="deprecation_rate" class="text-left col-4">Deprecation Rate</nz-form-label>
                <nz-form-control>
                  <nz-input-group nzSuffix="%" style="width:49%;">
                    <input id="deprecation_rate" type="text" nz-input formControlName="depreciationRate"
                      placeholder="Rate" style="border-radius: 8px" (input)="formatNumber($event,'depreciationRate')" />
                  </nz-input-group>
                  <nz-input-group nzSuffix="month" style="width:49%; margin-left: 2%;">
                    <input numbersOnly id="period" type="text" nz-input formControlName="period" placeholder="Period"
                      style="border-radius: 8px;" (input)="formatNumber($event,'period')" />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzFor="note" class="text-left col-4">Note</nz-form-label>
                <nz-form-control>
                  <textarea id="note" nz-input style=" border-radius: 8px; height: 100px;"
                    formControlName="note"></textarea>
                </nz-form-control>
              </nz-form-item>
            </div>

          </div>
        </nz-collapse-panel>

        <nz-collapse-panel [nzHeader]="'Vendor Details'" [nzActive]="false">
          <div class="row">
            <div class="col">
              <nz-form-item>
                <nz-form-label nzFor="vendor" class="text-left col-4">Vendor Name</nz-form-label>
                <nz-form-control>
                  <nz-select id="vendor" formControlName="vendor"
                    [nzDisabled]="assetsForm.get('company').value.length<1" (ngModelChange)="onChangeVendor($event)">
                    <ng-container *ngFor="let item of vendorList">
                      <nz-option [nzValue]="item.value" [nzLabel]="item.value"></nz-option>
                    </ng-container>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzFor="pic" class="text-left col-4">PIC Name</nz-form-label>
                <nz-form-control>
                  <input id="pic" type="text" formControlName="vendorPICName" nz-input style=" border-radius: 8px;" />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzFor="email" class="text-left col-4">Email</nz-form-label>
                <nz-form-control>
                  <input id="email" type="text" formControlName="vendorEmail" nz-input style=" border-radius: 8px;" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col">
              <nz-form-item>
                <nz-form-label nzFor="industries" class="text-left col-4">Industries</nz-form-label>
                <nz-form-control>
                  <input id="industries" type="text" formControlName="vendorIndustries" nz-input
                    style=" border-radius: 8px;" />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzFor="phoneNumber" class="text-left col-4">PhoneNumber</nz-form-label>
                <nz-form-control>
                  <input id="phoneNumber" type="text" formControlName="vendorPhone" nz-input
                    style=" border-radius: 8px;" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </form>
  </ng-template>

  <ng-template #addModalFooter>
    <button nz-button nzType="default" (click)="cancelAddInventories()">Cancel</button>
    <button nz-button nzType="primary" [nzLoading]="isAddAssetConfirm" (click)="handleAddAssetConfirm()"
      [disabled]="!this.assetsForm.valid">Confirm</button>
  </ng-template>
</nz-modal>

<nz-modal [nzVisible]="previewVisible" [nzContent]="modalContent" [nzFooter]="null"
  (nzOnCancel)="previewVisible = false">
  <ng-template #modalContent>
    <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
  </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="isViewVisible" [nzWidth]="'80%'" [nzTitle]="viewModalTitle" [nzContent]="viewModalContent"
  [nzFooter]="viewModalFooter" (nzOnCancel)="closeViewAsset()">

  <ng-template #viewModalTitle> View Asset</ng-template>

  <ng-template #viewModalContent>
    <nz-spin [nzSpinning]="isViewLoading">
      <form nz-form [formGroup]="assetViewForm">
        <nz-collapse>
          <nz-collapse-panel [nzHeader]="'Employee Data'" [nzActive]="true">
            <div class="row">
              <div class="col">
                <nz-form-item>
                  <nz-form-label nzFor="employee_name" class="text-left col-4">Employee Name</nz-form-label>
                  <nz-form-control>
                    <input nz-input id="employee_name" type="text" style="border-radius: 8px;"
                      formControlName="employeeName" [nzDisabled]="true" />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="department" class="text-left col-4">Department</nz-form-label>
                  <nz-form-control>
                    <nz-select id="department" formControlName="department" [nzDisabled]="true">
                      <nz-option [nzValue]="assetViewForm.value.department"
                        [nzLabel]="assetViewForm.value.department"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label class="text-left col-4">Employee Picture</nz-form-label>
                  <nz-form-control>
                    <span style="color:red; font-size:12px">The employee picture cannot be changed.</span>
                    <nz-upload nzName="picture" nzListType="picture-card" [nzLimit]="1"
                      [(nzFileList)]="viewEmployeePicture" [nzPreview]="handlePreview" [nzRemove]="false"
                      [nzAccept]="'image/jpeg, image/jpg, image/png'" [nzDownload]="handleDownload"
                      [nzDisabled]="viewEmployeePicture.length>1" [nzShowButton]="false">
                      <div>
                        <span nz-icon nzType="plus"></span>
                        <div style="margin-top: 8px">Upload</div>
                      </div>
                    </nz-upload>
                  </nz-form-control>
                </nz-form-item>

              </div>
              <div class="col">
                <nz-form-item>
                  <nz-form-label nzFor="company" class="text-left col-4">Company</nz-form-label>
                  <nz-form-control>
                    <nz-select id="company" formControlName="company" [nzDisabled]="true">
                      <nz-option [nzValue]="assetViewForm.value.company"
                        [nzLabel]="assetViewForm.value.company"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="phone_number" class="text-left col-4">Phone Number</nz-form-label>
                  <nz-form-control>
                    <input id="phone_number" formControlName="phoneNumber" type="text" nz-input
                      style=" border-radius: 8px" />
                  </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                  <nz-form-label nzFor="email" class="text-left col-4">Email</nz-form-label>
                  <nz-form-control>
                    <input id="email" type="text" nz-input style=" border-radius: 8px" formControlName="email" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </nz-collapse-panel>

          <nz-collapse-panel [nzHeader]="'Asset Data'" [nzActive]="false">
            <div class="row">
              <div class="col">
                <nz-form-item>
                  <nz-form-label nzFor="asset_name" class="text-left col-4">Asset Name</nz-form-label>
                  <nz-form-control>
                    <input id="asset_name" type="text" nz-input style=" border-radius: 8px"
                      formControlName="assetName" />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="serial_number" class="text-left col-4">Serial Number</nz-form-label>
                  <nz-form-control>
                    <input id="serial_number" type="text" nz-input style=" border-radius: 8px"
                      formControlName="serialNumber" />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="purchased_date" class="text-left col-4">Purchased Date</nz-form-label>
                  <nz-form-control>
                    <nz-date-picker formControlName="purchasedDate"></nz-date-picker>
                  </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                  <nz-form-label nzFor="status" class="text-left col-4">Status</nz-form-label>
                  <nz-form-control>
                    <nz-select id="status" formControlName="status"
                      [nzDisabled]="assetsForm.get('company').value.length<1">
                      <nz-option [nzValue]="assetViewForm.value.status"
                        [nzLabel]="assetViewForm.value.status"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="service" class="text-left col-4">Service</nz-form-label>
                  <nz-form-control>
                    <input id="service" type="text" nz-input style=" border-radius: 8px;" formControlName="service"
                      numbersOnly />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="serviceAt" class="text-left col-4">Service At</nz-form-label>
                  <nz-form-control>
                    <input id="serviceAt" type="text" nz-input style=" border-radius: 8px;"
                      formControlName="serviceAt" />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="location" class="text-left col-4">Location</nz-form-label>
                  <nz-form-control>
                    <nz-select id="location" formControlName="location" [nzDisabled]="true">
                      <nz-option [nzValue]="assetViewForm.value.location"
                        [nzLabel]="assetViewForm.value.location"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label class="text-left col-4">Asset Picture</nz-form-label>
                  <nz-form-control>
                    <span style="color:red; font-size:12px">The asset pictures cannot be changed.</span>

                    <nz-upload nzName="picture" nzListType="picture-card" [nzShowUploadList]="true" [nzMultiple]="false"
                      [(nzFileList)]="viewAssetPicture" [nzLimit]="1" [nzPreview]="handlePreview" [nzRemove]="false"
                      [nzDownload]="handleDownload" [nzDisabled]="true" [nzShowButton]="false">
                      <div>
                        <span nz-icon nzType="plus"></span>
                        <div style="margin-top: 8px">Upload</div>
                      </div>
                    </nz-upload>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label class="text-left col-4">Document</nz-form-label>
                  <nz-form-control>
                    <nz-upload nzName="file" nzListType="picture-card" [nzAccept]="'.pdf, image/jpeg, image/png'"
                      [nzShowUploadList]="true" [nzMultiple]="false" [(nzFileList)]="documentList" [nzLimit]="1"
                      [nzDownload]="handleDownload" [nzBeforeUpload]="beforeUpload" [nzCustomRequest]="customUpload"
                      [nzDisabled]="documentList.length>0" [nzRemove]="handleRemove"
                      (nzChange)="handleChangeEditDocument($event)" [nzShowButton]="!(documentList.length>0)">
                      <div>
                        <span nz-icon nzType="plus"></span>
                        <div style="margin-top: 8px">Upload</div>
                      </div>
                    </nz-upload>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div class="col">

                <nz-form-item>
                  <nz-form-label nzFor="type" class="text-left col-4">Type</nz-form-label>
                  <nz-form-control>
                    <nz-select id="type" formControlName="type" [nzDisabled]="true">

                      <nz-option [nzValue]="assetViewForm.value.type" [nzLabel]="assetViewForm.value.type"></nz-option>

                    </nz-select>
                    <!-- <input id="type" type="text" nz-input style=" border-radius: 8px;" /> -->
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="brand" class="text-left col-4">Brand</nz-form-label>
                  <nz-form-control>
                    <nz-select id="brand" formControlName="brand" [nzDisabled]="true">
                      <nz-option [nzValue]="assetViewForm.value.brand"
                        [nzLabel]="assetViewForm.value.brand"></nz-option>
                    </nz-select>
                    <!-- <input id="brand" type="text" nz-input style=" border-radius: 8px;" /> -->
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="condition" class="text-left col-4">Condition</nz-form-label>
                  <nz-form-control>
                    <nz-select id="condition" formControlName="condition" [nzDisabled]="true">
                      <nz-option [nzValue]="assetViewForm.value.condition"
                        [nzLabel]="assetViewForm.value.condition"></nz-option>
                    </nz-select>
                    <!-- <input id="condition" type="text" nz-input style=" border-radius: 8px;" /> -->
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="description" class="text-left col-4">Description</nz-form-label>
                  <nz-form-control>
                    <textarea id="description" nz-input style=" border-radius: 8px;height: 100px;"
                      formControlName="description"></textarea>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="initial_value" class="text-left col-4">Initial Value</nz-form-label>
                  <nz-form-control>
                    <nz-input-group nzPrefix="Rp.">
                      <input id="initial_value" type="text" nz-input formControlName="initialValue"
                        style="border-radius: 8px;" numbersOnly />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="deprecation_rate" class="text-left col-4">Deprecation Rate</nz-form-label>
                  <nz-form-control>
                    <nz-input-group nzSuffix="%" style="width:49%;">
                      <input numbersOnly id="deprecation_rate" type="text" nz-input formControlName="depreciationRate"
                        placeholder="Rate" style="border-radius: 8px;" />
                    </nz-input-group>
                    <nz-input-group nzSuffix="month" style="width:49%; margin-left: 2%;">
                      <input numbersOnly id="period" type="text" nz-input formControlName="period" placeholder="Period"
                        style="border-radius: 8px;" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="note" class="text-left col-4">Note</nz-form-label>
                  <nz-form-control>
                    <textarea id="note" nz-input style=" border-radius: 8px; height: 100px;"
                      formControlName="note"></textarea>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </nz-collapse-panel>

          <nz-collapse-panel [nzHeader]="'Vendor Details'" [nzActive]="false">
            <div class="row">
              <div class="col">
                <nz-form-item>
                  <nz-form-label nzFor="vendor" class="text-left col-4">Vendor Name</nz-form-label>
                  <nz-form-control>
                    <nz-select id="vendor" formControlName="vendor" (ngModelChange)="onChangeVendor($event)">
                      <ng-container *ngFor="let item of vendorList">
                        <nz-option [nzValue]="item.value" [nzLabel]="item.value"></nz-option>
                      </ng-container>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="pic" class="text-left col-4">PIC Name</nz-form-label>
                  <nz-form-control>
                    <input id="pic" type="text" formControlName="vendorPICName" nz-input style=" border-radius: 8px;" />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="email" class="text-left col-4">Email</nz-form-label>
                  <nz-form-control>
                    <input id="email" type="text" formControlName="vendorEmail" nz-input style=" border-radius: 8px;" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div class="col">
                <nz-form-item>
                  <nz-form-label nzFor="industries" class="text-left col-4">Industries</nz-form-label>
                  <nz-form-control>
                    <input id="industries" type="text" formControlName="vendorIndustries" nz-input
                      style=" border-radius: 8px; " />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label nzFor="phoneNumber" class="text-left col-4">PhoneNumber</nz-form-label>
                  <nz-form-control>
                    <input id="phoneNumber" type="text" formControlName="vendorPhone" nz-input
                      style=" border-radius: 8px;" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </nz-collapse-panel>

        </nz-collapse>
      </form>
    </nz-spin>
  </ng-template>

  <ng-template #viewModalFooter>
    <button nz-button nzType="default" (click)="closeViewAsset()">Cancel</button>
    <button nz-button nzType="primary" [nzLoading]="isUpdateAssetConfirm" (click)="confirmUpdate()">Update</button>
  </ng-template>
</nz-modal>
<ng-template #toaster>
  <div class="ant-notification-notice-content">
    <div class="ant-notification-notice-with-icon">
      <span class="ant-notification-notice-icon">
        <span nz-icon [nzType]="toasterIcon" [ngStyle]="{ color: toasterColor }"></span>
      </span>
      <div class="ant-notification-notice-message">Message :</div>
      <div class="ant-notification-notice-description">
        {{toasterMessage}}
      </div>
    </div>
  </div>
</ng-template>
<router-outlet></router-outlet>